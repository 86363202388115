import * as React from "react"
import { graphql, HeadFC, PageProps } from "gatsby"
import RichText from "../components/rich-text"

const IndexPage: React.FC<PageProps> = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center font-extralight text-2xl">
      Hello :)
    </div>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
